'use client';
import clsx from 'clsx';
import Link from 'next/link';
import { TbMenu2 } from 'react-icons/tb';
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/react';
import Image from 'next/image';
import { imageLoader } from '@/utils';

interface IProps {
  page: 'home' | 'pdf';
}

export default function Header(props: IProps) {
  return (
    <>
      {/* pc */}
      <div className='flex justify-center border-b-1 border-[#D9D9D9]'>
        <div className='header-container w-[1580px] h-20 px-[150px] flex items-center justify-between max-md:hidden '>
          <Link href="/">
            <Image src="/logo-header.png" alt='logo' width={140} height={42} loader={imageLoader} />
          </Link>
          <Link href="/pdf/1" className='z-20'>
            <span className={clsx('text-22-30 font-semibold z-10 cursor-pointer', props.page === 'pdf' ? 'text-[#C92A3A]' : '')}>PDF</span>
          </Link>
        </div>
      </div>


      {/* mobile */}
      <div className='hidden max-md:flex h-12 w-full px-4   items-center justify-between'>
        <Link href={'/'}>
          <Image  src="/logo-header.png"  loader={imageLoader} alt='logo' width={110} height={33} />
        </Link>
        <Popover
          classNames={{
            content: 'bg-transparent relative w-screen rounded-none',
            base: '-left-3',
          }}
          placement='bottom-start'
        >
          <PopoverTrigger>
            <div className='flex items-center'>
              <TbMenu2 className='w-6 h-6 text-black' />
            </div>
          </PopoverTrigger>
          <PopoverContent >
            <div className="lang-list w-screen py-5 absolute top-0 left-0  text-center bg-white text-18-27">
              <div
               className={ clsx(props.page === 'pdf' && 'text-[#C92A3A]', 'cursor-pointer') }
              >
                <Link href='/pdf/1'>
                  PDF
                </Link>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
